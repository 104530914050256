<template>
  <b-card-code title="Modifier un role">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de role </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  v-model="role.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editRoles"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
// import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      role: {},
      repository: {
        name: '',
      },
      required,
    }
  },
  created() {
    this.getRoles()
  },
  methods: {
    async getRoles() {
      // @ts-ignore
      const { id } = this.$route.params
      await axios.get(`/api/authentication/groups/detail/${id}`).then(response => {
        this.role = response.data
      })
    },
    async editRoles() {
      await axios
        .put(
          `/api/authentication/groups/update/${this.role.id}/`,
          {
            name: this.role.name,
          },
        )
        .then(() => {
          this.$router.push('/roles/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Role modifié avec succés')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
